/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ColorName {
    SLATE = 'slate',
    GRAY = 'gray',
    ZINC = 'zinc',
    NEUTRAL = 'neutral',
    STONE = 'stone',
    RED = 'red',
    ORANGE = 'orange',
    AMBER = 'amber',
    YELLOW = 'yellow',
    LIME = 'lime',
    GREEN = 'green',
    EMERALD = 'emerald',
    TEAL = 'teal',
    CYAN = 'cyan',
    BLUE = 'blue',
    INDIGO = 'indigo',
    VIOLET = 'violet',
    PURPLE = 'purple',
    FUCHSIA = 'fuchsia',
    PINK = 'pink',
    ROSE = 'rose',
    G2A_PURPLE_HO = 'g2a_purple_ho',
    G2A_ORANGE_VV = 'g2a_orange_vv',
    G2A_TEAL_RT = 'g2a_teal_rt',
    G2A_GRAY_NC = 'g2a_gray_nc',
    G2A_YELLOW_PAP = 'g2a_yellow_pap',
    G2A_GREEN_CP_NC = 'g2a_green_cp_nc',
    G2A_RED_PRO_NC = 'g2a_red_pro_nc',
    G2A_NAVY_AI_NC = 'g2a_navy_ai_nc',
    G2A_CYAN_NC = 'g2a_cyan_nc',
}
