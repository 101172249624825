import {
  AppCLient,
  FewShotTaggingModelView,
  NewWorkspaceFewShotTaggingModels,
} from "../../app_client";
import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../../AuthUserContext";

export type FewShotTaggingModelViewWithLinks = FewShotTaggingModelView & {
  trainingDataMap: string;
  trainingDataXlsx: string;
  evaluationMetricsPage: string;
  F1ScoreHeatMap: string;
  PrecisionRecallCurve: string;
  RocCurve: string;
  RawData: string;
};


export interface FewShotTaggingModelsApi {
  fetchModel(model_id: string): Promise<FewShotTaggingModelView>;
  fetchModels(): Promise<FewShotTaggingModelViewWithLinks[]>;
  getModel(model_id: string): Promise<FewShotTaggingModelViewWithLinks >;
  downloadTrainingData(model: FewShotTaggingModelView): Promise<void>;
  createModel(
    data: Omit<NewWorkspaceFewShotTaggingModels, "workspace_id">
  ): Promise<void>;
  disableModel(model: FewShotTaggingModelView): Promise<void>;
  triggerTraining(model: FewShotTaggingModelView): Promise<void>;
  triggerEvaluate(model: FewShotTaggingModelView): Promise<void>;
}

export const useFewShotTaggingModelApi = (): FewShotTaggingModelsApi => {
  const [api, setApi] = useState<FewShotTaggingModelsApi | null>(null);
  const { token, workspaceId } = useContext(AuthUserContext) as {
    token: string;
    workspaceId: string;
  };

  useEffect(() => {
    if (token && workspaceId) {
      let client = new AppCLient({
        BASE: process.env.REACT_APP_APP_API_HOST,
        TOKEN: token,
      });

      setApi({
        fetchModel: (model_id: string) => {
          return client.fewShotTaggingModel.getFewShotTaggingFewshotTaggingModelModelIdGet(
              model_id
          )
        },
        fetchModels: async () => {
          let models = await client.workspace.getFewShotTaggingModelsWorkspaceWorkspaceIdModelsFewShotTaggingGet(
            workspaceId
          );
          return models.map((model) => {
            return {
              ...model,
              trainingDataMap: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model.model_id}/training_data/plot_embedding.html`,
              trainingDataXlsx: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model.model_id}/training_data.xlsx`,
              evaluationMetricsPage: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model.model_id}/report/evaluation_metrics.html`,
              F1ScoreHeatMap: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model.model_id}/report/recall_precision_curve.html`,
              PrecisionRecallCurve: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model.model_id}/report/heatmap.html`,
              RocCurve: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model.model_id}/report/roc_curve.html`,
              RawData: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model.model_id}/evaluation_metrics.xslx`,
            }
          })
        },
        getModel: async (model_id: string) => {
            let model = await client.fewShotTaggingModel.getFewShotTaggingFewshotTaggingModelModelIdGet(
                model_id
            );
            return {
              ...model,
              trainingDataMap: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model_id}/training_data/plot_embedding.html`,
              trainingDataXlsx: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model_id}/training_data.xlsx`,
              evaluationMetricsPage: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model_id}/report/evaluation_metrics.html`,
              F1ScoreHeatMap: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model_id}/report/recall_precision_curve.html`,
              PrecisionRecallCurve: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model_id}/report/heatmap.html`,
              RocCurve: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model_id}/report/roc_curve.html`,
              RawData: `${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model_id}/evaluation_metrics.xslx`,
            }
        },
        createModel: (data:Omit<NewWorkspaceFewShotTaggingModels, "workspace_id">) => {
          return client.workspace.createFewShotTaggingModelWorkspaceWorkspaceIdFewShotTaggingModelPost({
            workspace_id: workspaceId,
            ...data
          })
        },
        downloadTrainingData: async (model: FewShotTaggingModelView) => {
          window.open(`${process.env.REACT_APP_APP_API_HOST}/fewshot_tagging_model/${model.model_id}/training_data.xlsx`, '_blank', 'noopener,noreferrer');
        },
        disableModel: (model: FewShotTaggingModelView) => {
            return client.workspace.deleteFewShotTaggingModelWorkspaceWorkspaceIdFewShotTaggingModelModelIdDelete(
                workspaceId, model.model_id
            )
        },
        triggerTraining: async (model: FewShotTaggingModelView) => {
          await client.fewShotTaggingModel.triggerTrainModelFewshotTaggingModelModelIdTrainPost(model.model_id)
        },
        triggerEvaluate: async (model: FewShotTaggingModelView) => {
          await client.fewShotTaggingModel.rerunEvaluationFewshotTaggingModelRerunEvaluationModelIdPost(model.model_id)
        }
      });


    }
  }, [token, workspaceId]);

  return api as FewShotTaggingModelsApi;
};
